/* RTL specific overrides */
body.rtl {
  text-align: right;
  direction: rtl;
}

/* Flip icons in RTL mode */
body.rtl .icon-flip-rtl {
  transform: scaleX(-1);
}

/* Adjust layout for RTL */
body.rtl .flex-row {
  flex-direction: row-reverse;
}

body.rtl .header nav {
  flex-direction: row-reverse;
}

body.rtl .right-section {
  flex-direction: row-reverse;
}

/* Adjust margins/paddings */
body.rtl .ml-auto {
  margin-left: unset;
  margin-right: auto;
}

body.rtl .mr-auto {
  margin-right: unset;
  margin-left: auto;
}

/* Search icon positioning */
body.rtl .UniSearchIconWrapper {
  left: auto;
  right: 0;
}

body.rtl .UniSearchInput {
  padding: 0 50px 0 20px;
}

/* Card content */
body.rtl .university-info {
  padding-right: 100px;
  padding-left: 0;
}

body.rtl .university-image-container {
  left: auto;
  right: 20px;
}

body.rtl .fees-icon {
  margin-right: 0;
  margin-left: 5px;
}

body.rtl .university-tags {
  flex-direction: row-reverse;
}

body.rtl .tag {
  flex-direction: row-reverse;
}

/* University detail page */
body.rtl .UniversityLogoContainer {
  left: auto;
  right: 40px;
}

body.rtl .HeroContent {
  padding: 20px 180px 20px 30px;
}

body.rtl .section-icon {
  margin-right: 0;
  margin-left: 8px;
}

/* Form fields */
body.rtl label {
  text-align: right;
}

/* Dropdown menus */
body.rtl .dropdown-menu {
  left: auto;
  right: 0;
}

/* Admission badge */
body.rtl .admission-badge {
  right: auto;
  left: 15px;
}

/* Favorite button */
body.rtl .FavoriteButton {
  right: auto;
  left: 15px;
}

/* Media queries for responsive RTL support */
@media (max-width: 768px) {
  body.rtl .university-info {
    padding-right: 90px;
  }
  
  body.rtl .HeroContent {
    padding: 20px 120px 20px 20px;
  }
}

@media (max-width: 480px) {
  body.rtl .university-info {
    padding-right: 70px;
  }
}