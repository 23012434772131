/* BackgroundPattern.css */
body, html {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.background-pattern {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.pattern-container {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%; 
  height: 200%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 24 24' fill='none' stroke='rgba(37, 99, 235, 0.08)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M22 10v6M2 10l10-5 10 5-10 5z'%3E%3C/path%3E%3Cpath d='M6 12v5c0 2 2 3 6 3s6-1 6-3v-5'%3E%3C/path%3E%3C/svg%3E");
  background-size: 60px 60px;
  transform: rotate(-45deg);
  opacity: 0.5;
}

.background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.8) 100%);
  z-index: -1;
}

/* Ensure the App container takes full height */
#root, .App {
  min-height: 100vh;
  position: relative;
}