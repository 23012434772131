/* Mobile-first Base Styles */
html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color:#f0f9ff;
  line-height: 1.6;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 24 24' fill='none' stroke='rgba(37, 99, 235, 0.08)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M22 10v6M2 10l10-5 10 5-10 5z'%3E%3C/path%3E%3Cpath d='M6 12v5c0 2 2 3 6 3s6-1 6-3v-5'%3E%3C/path%3E%3C/svg%3E");
  background-size: 60px 60px;
  opacity: 1;
  z-index: -1;
  pointer-events: none;
}
* {
  box-sizing: border-box;
}

/* Optimize for touch targets */
button, 
a, 
input, 
select, 
textarea {
  touch-action: manipulation;
}

button, 
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Root and App Container */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 64px; /* Updated header height */
  z-index: 1;
}

.content-wrapper {
  flex: 1;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

/* Typography */
h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

p {
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

/* Container Layouts */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.page-container {
  padding: 20px 16px;
  margin-top: 0; /* Removed top margin */
}

/* Cards and Boxes */
.card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Form Elements */
input, 
select, 
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 16px;
}

input:focus, 
select:focus, 
textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

/* Buttons */
.btn {
  display: inline-block;
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  transition: background 0.3s ease;
  min-height: 44px; /* Better touch target */
}

.btn:hover {
  background: #0056b3;
}

.btn-outline {
  background: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.btn-outline:hover {
  background: rgba(0, 123, 255, 0.1);
}

/* Header spacing adjusted for mobile */
.custom-header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  padding: 10px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

/* Grid Layouts */
.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

/* Loading States */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.spinner-image {
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Footer */
.custom-footer {
  background-color: white;
  padding: 20px 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  margin-top: auto;
}

/* Error States */
.error-message {
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 0.9rem;
}

/* Favorites button */
.favorite-btn {
  background: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: #ccc;
  transition: all 0.2s ease;
}

.favorite-btn.active {
  color: #e74c3c;
}

.favorite-btn:hover {
  transform: scale(1.1);
}

/* Animations */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Search Elements */
.search-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.search-box {
  position: relative;
}

.search-input {
  padding-left: 40px;
}

.search-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

/* Common Components */
.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
}

.badge-primary {
  background-color: #e6f0ff;
  color: #007bff;
}

.badge-secondary {
  background-color: #fdebd0;
  color: #f39c12;
}
/* Responsive Breakpoints */
@media (min-width: 480px) {
  .page-container {
    padding: 24px;
  }
  
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.6rem;
  }
  
  h3 {
    font-size: 1.3rem;
  }
}

@media (min-width: 768px) {
  .App {
    padding-top: 64px; /* Keep consistent with header height */
  }
  
  .content-wrapper {
    padding: 0 24px;
  }
  
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .search-container {
    flex-direction: row;
    align-items: center;
  }
  
  h1 {
    font-size: 2.2rem;
  }
  
  .custom-header {
    padding: 0 24px;
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .content-wrapper {
    padding: 0 32px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
}

/* Touch Device Optimization */
@media (hover: none) {
  .card:hover {
    transform: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Increase spacing between touch targets */
  .nav-link, 
  .dropdown-item, 
  .btn {
    padding: 12px 20px;
  }
  
  /* Enhance tap targets */
  input,
  select,
  textarea,
  button,
  .nav-link {
    min-height: 44px;
  }
}

/* Fix for iOS inputs */
input, 
select, 
textarea {
  -webkit-appearance: none;
  appearance: none;
}
/* Touch Device Optimizations - Add these to your App.css */

/* Optimize for touch targets */
button, 
a, 
input, 
select, 
textarea {
  touch-action: manipulation;
}

/* Ensure all touch targets are at least 44x44px */
@media (pointer: coarse) {
  button,
  .nav-link,
  .mobile-nav-link,
  .dropdown-item,
  input[type="button"],
  input[type="submit"],
  input[type="reset"] {
    min-height: 44px;
    min-width: 44px;
  }
  
  /* Increase touch target size for icons */
  .nav-icon,
  .dropdown-icon,
  .button-icon {
    font-size: 1.2rem;
  }
  
  /* Increase padding for better touch experience */
  .nav-link,
  .mobile-nav-link,
  .dropdown-item {
    padding: 12px 16px;
  }
  
  /* Larger toggle buttons */
  .mobile-menu-button,
  .mobile-menu-close {
    padding: 8px;
    min-width: 44px;
    min-height: 44px;
  }
  
  /* Increase form element sizes */
  input,
  select,
  textarea {
    font-size: 16px; /* Prevents iOS zoom on focus */
    padding: 12px;
  }
}

/* Fix iOS form element styling */
input,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 8px; /* Consistent border radius */
}

/* Fix delayed tap highlight on mobile */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Fix for iOS sticky hover states */
@media (hover: none) {
  .nav-link:hover,
  .mobile-nav-link:hover,
  .dropdown-item:hover,
  button:hover {
    /* Reset hover states on touch devices */
    background-color: inherit;
    color: inherit;
  }
  
  /* Only apply hover effects on active states for touch devices */
  .nav-link:active,
  .mobile-nav-link:active,
  .dropdown-item:active,
  button:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

/* Fix for proper viewport height on mobile browsers */
:root {
  --app-height: 100%;
}

@supports (-webkit-touch-callout: none) {
  body,
  .mobile-menu,
  .mobile-menu-overlay {
    height: var(--app-height);
  }
}

/* Add this script to your main component mount function to fix iOS height issues
   window.addEventListener('resize', () => {
     document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
   });
   document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
*/