.footer {
  width: 100%;
  background: #f1faff;
  box-shadow: 0 -4px 15px rgba(37, 99, 235, 0.08);
  padding: 30px 0 20px;
  position: relative;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px 0;
}

.footer-logo-section {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.footer-logo-link {
  display: flex;
  align-items: center;
}

.footer-logo {
  height: 50px;
  width: auto;
}

.footer-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.footer-link {
  text-decoration: none;
  color: #2c3e50;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.footer-link:hover {
  background: #e0f2fe;
  color: #2563eb;
}

.social-links {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: #2c3e50;
  background: #e3f4ff;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 1.2rem;
}

.social-link:hover {
  background: #dbeafe;
  color: #2563eb;
  transform: translateY(-2px);
}

.footer-bottom {
  text-align: center;
  padding-top: 16px;
  border-top: 1px solid #e0f2fe;
}

.copyright {
  color: #7f8c8d;
  font-size: 0.85rem;
}

/* Scroll to top button */
.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #2563eb;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(37, 99, 235, 0.3);
  z-index: 999;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.scroll-top-button:hover {
  background: #1d4ed8;
  transform: translateY(-4px);
  opacity: 1;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .footer {
    padding: 24px 0 16px;
  }
  
  .footer-logo {
    height: 40px;
  }
  
  .footer-logo-section {
    margin-bottom: 20px;
  }
  
  .footer-sections {
    gap: 20px;
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .footer {
    padding: 20px 0 12px;
  }
  
  .footer-logo {
    height: 36px;
  }
  
  .footer-nav {
    gap: 8px;
  }
  
  .footer-link {
    padding: 6px 10px;
    font-size: 0.85rem;
  }
  
  .social-link {
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
  
  .copyright {
    font-size: 0.8rem;
  }
  
  .scroll-top-button {
    width: 40px;
    height: 40px;
    bottom: 16px;
    right: 16px;
  }
}

/* Larger screens */
@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 24px 0;
  }
  
  .footer-logo-section {
    margin-bottom: 0;
  }
  
  .footer-sections {
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    gap: 40px;
  }
}