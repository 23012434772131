.header {
  width: 100%;
  background: #f1faff;
  box-shadow: 0 2px 10px rgba(37, 99, 235, 0.1);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px; /* Set consistent height */
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
}

.header-content {
  display: flex;
  align-items: center;
  height: 100%; /* Fill the container height */
  position: relative; /* For absolute positioning of desktop-nav */
}

.logo-link {
  display: flex;
  align-items: center;
  z-index: 1001; /* Ensure logo stays above mobile menu */
  margin-right: 15px;
}

.logo {
  height: 60px; /* Increased from 40px */
  width: auto;
}

/* Search Bar Styling */
.search-bar-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: auto;
  padding: 0 15px;
  z-index: 998;
}

@media (max-width: 1100px) {
  .search-bar-container {
    max-width: 300px;
  }
}

@media (max-width: 992px) {
  .search-bar-container {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .search-bar-container {
    display: none; /* Hide on mobile, we'll show it in the menu instead */
  }
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  text-decoration: none;
  color: #2c3e50;
  border-radius: 8px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background: #e0f2fe;
  color: #2563eb;
}

.nav-icon {
  font-size: 1.2em;
  color: #2563eb;
}

/* User Profile Section */
.user-section {
  position: relative;
  z-index: 1001;
  margin-left: 15px; /* Reduced from auto */
}

.user-dropdown {
  position: relative;
}

.user-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  transition: all 0.2s ease;
  outline: none;
  border-radius: 50%;
}

.user-button:hover {
  background-color: rgba(37, 99, 235, 0.1);
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.3s ease;
}

.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2563eb;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(37, 99, 235, 0.15);
  min-width: 280px;
  overflow: hidden;
  z-index: 1002;
  animation: fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.dropdown-menu.closing {
  animation: fadeOut 0.2s ease-in-out forwards;
  pointer-events: none;
}

.dropdown-header {
  padding: 16px;
}

.dropdown-profile {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.dropdown-profile-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  border: 1px solid #e0f2fe;
}

.dropdown-profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropdown-profile-info {
  flex: 1;
}

.dropdown-profile-name {
  font-weight: 500;
  font-size: 16px;
  color: #2c3e50;
  margin-bottom: 4px;
}

.dropdown-profile-email {
  font-size: 14px;
  color: #7f8c8d;
}

.dropdown-divider {
  height: 1px;
  background-color: #e0f2fe;
  margin: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  color: #2c3e50;
  transition: background 0.3s ease;
  font-size: 0.9rem;
  text-decoration: none;
}

.dropdown-item:hover {
  background: #e0f2fe;
}

.dropdown-icon {
  font-size: 1.1em;
  margin-right: 12px;
  color: #2563eb;
}

/* Sign In Button */
.sign-in-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background: #2563eb;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}

.sign-in-button:hover {
  background: #1d4ed8;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(37, 99, 235, 0.3);
}

.sign-in-icon {
  font-size: 1.1em;
  margin-right: 8px;
}

/* Mobile Menu Toggle Button */
.mobile-menu-button {
  display: none;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #2c3e50;
  cursor: pointer;
  padding: 8px;
  z-index: 1001;
}

/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 80%;
  max-width: 320px;
  height: 100vh;
  background: white;
  box-shadow: -4px 0 15px rgba(37, 99, 235, 0.1);
  z-index: 1002;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.mobile-menu.open {
  right: 0;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  animation: fadeIn 0.2s ease-in-out forwards;
}

.mobile-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0f2fe;
}

.mobile-profile-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mobile-profile-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.mobile-profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-profile-name {
  font-weight: 500;
  font-size: 16px;
  color: #2c3e50;
}

.mobile-profile-email {
  font-size: 13px;
  color: #7f8c8d;
}

.mobile-menu-close {
  background: transparent;
  border: none;
  font-size: 24px;
  color: #2c3e50;
  cursor: pointer;
}

.mobile-nav-links {
  flex: 1;
  overflow-y: auto;
  padding: 16px 0;
}

.mobile-nav-link {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none;
  color: #2c3e50;
  font-size: 1rem;
  border-bottom: 1px solid #f5f5f5;
  transition: background 0.2s ease;
}

.mobile-nav-link:hover {
  background: #e0f2fe;
  color: #2563eb;
}

.mobile-nav-link .nav-icon {
  margin-right: 12px;
  color: #2563eb;
}

.mobile-menu-footer {
  padding: 16px;
  border-top: 1px solid #e0f2fe;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-menu-button-styled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #f0f9ff;
  color: #2c3e50;
}

.mobile-menu-button-styled .button-icon {
  margin-right: 10px;
  color: #2563eb;
}

.mobile-menu-button-styled.logout {
  background: #ef4444;
  color: white;
}

.mobile-menu-button-styled:hover {
  background: #dbeafe;
  transform: translateY(-2px);
}

.mobile-menu-button-styled.logout:hover {
  background: #dc2626;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .header-content {
    height: 60px;
    padding: 0 10px;
    justify-content: space-between; /* Space between logo and menu button */
  }

  .logo {
    height: 45px;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-menu-button {
    display: block;
    position: absolute;
    right: 16px; /* Position explicitly on the right */
    top: 50%;
    transform: translateY(-50%);
  }

  .user-dropdown {
    display: none;
  }
  
  /* Make the header container relative for absolute positioning */
  .header-container {
    position: relative;
  }
  
  /* Ensure logo takes appropriate space */
  .logo-link {
    flex: 0 0 auto;
  }
  
  /* User section should be properly aligned */
  .user-section {
    margin-left: auto;
    margin-right: 60px; /* Make space for the mobile menu button */
  }
}

/* Extra small devices */
@media (max-width: 480px) {
  .header-container {
    padding: 0 16px;
  }

  .logo {
    height: 40px;
  }

  .sign-in-button {
    padding: 8px 16px;
    font-size: 0.85rem;
  }
  
  .mobile-menu {
    width: 85%;
  }
}